<!--
 * @Description: 
 * @version: 1.0
 * @Author: Sunwx
 * @Date: 2021-10-24 10:58:49
 * @LastEditors: Sunwx
 * @LastEditTime: 2021-10-24 11:50:58
-->
<template>
	<div class="history-traffic-class">
		<div class="history-traffic-class-top">
			<div class="history-traffic-class-top-rightTitle">
				<div class="block">
					<span class="demonstration" style="font-size: 18px;margin-right: 10px;">日期选择</span>
					<el-date-picker v-model="dateScope" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" style="margin-right: 10px;">
					</el-date-picker>
				</div>
				<el-button type="primary" @click="queryClick">查询</el-button>
				<el-button type="primary" @click="exportClick">导出</el-button>
			</div>
		</div>
		<div class="history-traffic-class-bottom">
			<my-echarts :option="traffic.option" :is-loading="traffic.isLoading"></my-echarts>
		</div>
		<div class="history-traffic-class-total">
			<span>结果统计</span>
			<el-row>
				<el-col :span="24">
					<div class="text-pid">
						<div class="left-text">日均下泄流量</div>
						<div class="right-text">3.5872㎡/s</div>
					</div>
					<div class="text-pid">
						<div class="left-text">未达标时间</div>
						<div class="right-text">0小时</div>
					</div>
					<div class="text-pid">
						<div class="left-text">数据缺失时间</div>
						<div class="right-text">0.00小时</div>
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<div class="text-pid">
						<div class="left-text">最小下泄流量</div>
						<div class="right-text">3.5872㎡/s</div>
					</div>
					<div class="text-pid">
						<div class="left-text">未达标率</div>
						<div class="right-text">0小时</div>
					</div>
					<div class="text-pid">
						<div class="left-text">数据缺失率</div>
						<div class="right-text">0.00小时</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>
<script>
	const MyEcharts = () => import("@/views/second/ECharts")
	export default {
		data() {
			return {
				dateScope: '',
				traffic: {
					isLoading: false,
					option: {
						xAxis: {
							type: 'category',
							boundaryGap: false,
							data: []
						},
						yAxis: {
							type: 'value'
						},
						series: [{
								data: [],
								type: 'line',
								areaStyle: {}
							},
							{
								data: [
									350, 430, 524, 418, 635, 547, 560, 350, 430, 524, 418, 635,
									350, 430, 524, 418, 635, 547, 560, 350, 430, 524, 418, 635
								],
								type: 'line'
							}
						]
					}
				}
			};
		},
		components: {
			MyEcharts,
		},
		created() {
			this.initXAxisData();
		},
		watch: {

		},
		methods: {
			/**
			 * @description: 初始化x坐标
			 * @param {*}
			 * @return {*}
			 * @author: Sunwx
			 */
			initXAxisData() {
				this.traffic.option.xAxis.data = [];
				this.traffic.option.series.data = [];
				var xAxis = [],
					series = [];
				for (var i = 0; i < 24; i++) {
					xAxis.push((i < 10 ? ("0" + i) : i) + ":00");
					series.push(420);
				}
				this.traffic.option.series[0].data = series;
				this.traffic.option.xAxis.data = xAxis;


			},
			/**
			 * @description: 查询事件
			 * @param {*}
			 * @return {*}
			 * @author: Sunwx
			 */
			queryClick() {
				alert("查询");
			},
			/**
			 * @description: 导出事件
			 * @param {*}
			 * @return {*}
			 * @author: Sunwx
			 */
			exportClick() {
				alert("导出");
			},
		}
	}
</script>
<style>
	.history-traffic-class {
		width: 100%;
		height: 100%;
	}

	.history-traffic-class-top {
		width: 100%;
		height: 50PX;
	}

	.history-traffic-class-top-leftTitle {
		width: 200px;
		font-size: 20px;
		float: left;
		margin-top: 8px;
		margin-left: 10px;
	}

	.history-traffic-class-top-rightTitle {
		float: right;
		margin-top: 2px;
		margin-right: 8px;
		display: flex;
	}

	.history-traffic-class-bottom {
		width: 100%;
		height: 300px;
	}

	.left-text {
		float: left;
		background-color: rgb(74, 119, 243);
		color: white;
		font-size: 18px;
		padding: 10px;
		margin-left: 50px;
		margin-top: 20px;
		width: 120px;
	}

	.right-text {
		float: left;
		border: solid 1px gray;
		font-size: 18px;
		padding: 9px;
		margin-top: 20px;
		width: 120px;
	}
</style>
